const getDateRange = (days) => {
  const now = new Date();
  // Set the end date to the end of the current day
  const endDate = new Date(now.setHours(23, 59, 59, 999)).toISOString();
  // Set the start date to 'days' ago at the start of the day
  const startDate = new Date(now.setDate(now.getDate() - days)).setHours(0, 0, 0, 0);

  return { startDate: new Date(startDate).toISOString(), endDate };
};

export default getDateRange;
