/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import http from 'utils/http';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';
import { nameComparator } from './comparators';

/**
 * A redux thunk action creator for verifying otp. It will take in
 * the otp and make the appropriate api call with the appropriate
 * headers and if valid set the new jwt and return the
 * decoded profile from jwt, if invalid just return an object with
 * invalid.
 */
export const getRobots = createAsyncThunk(
  'robotops/getRobots',
  async (params, thunkAPI) => {
    try {
      const response = await http.get(`/robot/`, { params:
         { ...params,
           // eslint-disable-next-line max-len
           fields: 'robot_id, property_id, status, status_details, robot_code, nick_name, model_id, activation_date,is_active,last_heartbeat_at,  deploy_va_engine, va_engine_status',
           filter: params?.filter } });
      return response;
    } catch (e) {
      if (e.code !== 'ERR_NETWORK') {
        thunkAPI.dispatch(enqueueSnackbar({
          message: e?.response?.data?.message,
          isClearable: true,
          variant: 'error',
          key: new Date().getTime() + Math.random()
        }));
      }
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateRobotConfig = createAsyncThunk(
  'robotops/updateRobotConfig',
  async (params, thunkAPI) => {
    try {
      const res = await http.put(`/robot/`, params);
      thunkAPI.dispatch(enqueueSnackbar({
        message: 'Updated robot configuration',
        isClearable: true,
        variant: 'success',
        key: new Date().getTime() + Math.random()
      }));
      return res;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: 'Unable to update robot configuration',
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return null;
    }
  }
);

export const defaultRobotFilters = {
  view: 'card',
  siteFilter: [],
  statusFilter: 'Active',
  selectedSort: {
    name: 'Sort A-Z',
    id: 0,
    sorter: nameComparator
  },
  searchKey: ''
};

/**
 * The slice that involves authentication, to help track of log in status
 * (whether the user is logged in or in the two factor authentication (tfa)
 * stage etc).
 */
const robotopsSlice = createSlice({
  name: 'robotops',
  initialState: {
    robots: [],
    isRobotsLoading: false,
    robotFilters: defaultRobotFilters
  },
  reducers: {
    setRobots(state, action) {
      state.robots = action.payload;
    },
    setRobotFilters(state, action) {
      state.robotFilters = action.payload;
    }
  },
  extraReducers: {
    [getRobots.pending]: (state) => {
      state.isRobotsLoading = true;
    },
    [getRobots.fulfilled]: (state, action) => {
      state.robots = action.payload?.data?.data;
      state.isRobotsLoading = false;
    },
    [getRobots.rejected]: (state) => {
      state.isRobotsLoading = false;
    }
  }
});

export const selectRobots = (state) => (state.robotops.robots);
export const selectRobotsLoading = (state) => (state.robotops.isRobotsLoading);
export const selectRobotFilters = (state) => (state.robotops.robotFilters);
export const { setRobots, setRobotFilters } = robotopsSlice.actions;

const { reducer } = robotopsSlice;
export default reducer;
